import { createApp } from 'vue'
import App from './App.vue'

// --- início rotas
import { createRouter, createWebHashHistory } from 'vue-router'
import ComprovanteDeVendasNfeVue from "@/components/comprovante-de-vendas-nfe/ComprovanteDeVendasNfe.vue"

const routes = [
    {
      path: '/comprovante-de-vendas-nfe/:id_venda', 
      component: ComprovanteDeVendasNfeVue
    },
   
] 

const router = createRouter({
    history: createWebHashHistory(),
    routes //routes: routes
})

// --- fim rotas

const Vue = createApp(App)
Vue.use(router) //adicionando as configurações de rotas a instância do Vue
Vue.mount('#app')

