<template>
    <div id="loading">
        <img
        class="img"
        src="../../assets/loading.gif"
        alt="loading..."
        width="200"
        />
     </div>

</template>
  
<script>
export default {
  name: 'CarregandoParaTelaVendasNFE',
  
}
</script>
<style scoped>

</style>
  