<template>
    <div>
        <div v-if="isLoading">
            <div class="container">
            <div class="row justify-content-center mt-5">
            <div class="col-lg-5 col-md-6 col-sm-6">
                <div class="card-title text-center">
                    <div id="loading">
                        <CarregandoParaTelaVendasNFEVue/>
                    </div>

                </div>
            </div>
            </div>
            </div>
        </div>

        <div  v-if="!isLoading && this.erro === false">
          
            <div class="container">
            <div class="row justify-content-center mt-5">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="card-title text-center">
                        <img src="../../assets/logo.png" width="200" height="50" alt=""/>
                        <br>
                        <h6 style="color:#6C6C6C" class="p-3"><strong>AUXILIAR DA NOTA FISCAL DE CONSUMIDOR ELETRÔNICA </strong> </h6>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="container">
            <div class="row justify-content-center mt-5">
            <div class="col-lg-5 col-md-6 col-sm-6">
                <div  class="card shadow ">
                <div class="card-title text-center">
                <div class="txtCenter">
                    <div id="u20" style="color:#6699CC;" class="txtTopo">WD VENDING MACHINE LTDA</div>
                    <div class="text" style="font-size:12px">CNPJ:20.874.587/0001-75</div>
                    <div class="text"  style="font-size:12px">RUA FREI HENRIQUE DE COIMBRA, 174,GALPAO,PITUACU,SALVADOR,BA</div>

                </div>
                </div>
                <div class="card-body">
                    <form>
                        
                        <!-- 
                            Estrutura search esta pronta, foi comentado pois pediu para tirar, entretanto caso precise é so descomentar...
                            <div class="content-dois">
                            <div class="search-dois">
                               
                                <i class="uil uil-search"></i>
                                <input type="text" v-model="search" placeholder="Filtrar produto">
                            </div>
                            </div>  
                         -->
                      
                        <!-- Table das informações -->
                        <div style="overflow: scroll; height: 100%; width: 100%">
                            <table  class="invoives-table" border="1">
                                <thead>
                                    <tr>
                                        <td>Produto</td>
                                        <td>Quantidade</td>
                                        <td>Preço</td>
                                        <td>Vl. Total</td>
                                    </tr>
                                </thead>
                               
                                 <tbody v-for="produto in this.searchProd" :key="produto.cod_prod">
                                    <tr>
                                        <td>{{ produto.nome}}</td>
                                        <td>{{produto.qtd}}Un</td>
                                        <td>R${{produto.valor}}</td>
                                        <td>R${{produto.total}}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="3" align="right">Total:</td>
                                        <td>{{this.details.total}}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" align="right">Qtd. total de itens:</td>
                                        <td>{{ this.details.qtd }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" align="right">Forma de Pagamento:</td>
                                        <td>{{this.details.forma_pagamento}}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" align="right">Valor Pago R$:</td>
                                        <td style="color:green"><strong>R${{ this.details.total- this.details.desconto }}</strong></td>
                                    </tr>
                                </tfoot>
                        </table>
                        </div>
                    </form>
                </div>
                </div>
            </div>
            </div>


        </div>

        <div class="container">
            <div class="row justify-content-center mt-5">
            <div class="col-lg-5 col-md-6 col-sm-6">

                <div class="card-title text-center"  v-on:click="nfChaveUrlCpf">
                <div class="accordion">
                    <!-- Accordion: Caso precise manutenção https://www.youtube.com/results?search_query=Accordion+++bootstrap -->
                    <div>
                        <input type="radio" name="example_accordion" id="section1" class="accordion__input">
                        <label for="section1" class="accordion__label">Informações gerais da nota</label>
                        <div class="accordion__content">
                        <p v-for="(value, key) in this.nfDetails" :key="key" >
                            {{key.toUpperCase() + ":" + value}}
                           
                        </p>
                        </div>
                    </div>

                    <div >
                        <input type="radio" name="example_accordion" id="section2" class="accordion__input">
                        <label for="section2" class="accordion__label">Chave de acesso</label>
                        <div  class="accordion__content">
                        
                        <p >Consulte pela Chave de Acesso em <br>{{this.urlNF }}</p>
                        <p>Chave de acesso:<br> {{ this.chaveNF }}</p> 
                        
                        </div>
                    </div>

                    <div>
                        <input type="radio" name="example_accordion" id="section3" class="accordion__input">
                        <label for="section3" class="accordion__label">Consumidor</label>
                        <div class="accordion__content">
                        <p>{{ this.cpf }}</p>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>  

        <div v-if="erro == true && !isLoading">
            <div class="container">
            <div class="row justify-content-center mt-5">
            <div class="col-lg-5 col-md-6 col-sm-6">
                <div class="card-title text-center">
                    Nenhuma Venda encontrada!!!

                </div>
            </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
import CarregandoParaTelaVendasNFEVue from './CarregandoParaTelaVendasNFE.vue';

export default{
  name: 'ComprovanteDeVendasNfe',
  data (){
    return {
        isLoading: true,
        search: null,
        details: [],
        nf: [],
        chaveNF: "",
        urlNF: "",
        cpf:"",
        erro : true
    }

  },
  components: {
    CarregandoParaTelaVendasNFEVue
  },
  mounted () {
    setTimeout(() => {
    axios.get(process.env.VUE_APP_ROOT_API_BASE+process.env.VUE_APP_ROOT_API_DETAILS+process.env.VUE_APP_ROOT_API_PARAMS+this.$route.params.id_venda).then(response => {
        this.details = response.data.data;
        this.nf = this.details.nf;
        this.erro = false;
        
    } ).catch(function(error){
        console.log(error)
    
    });
    this.isLoading = false;}, 1000);
    
    
  },
  computed :{
    searchProd(){
        if(this.search){
            return this.details.produtos.filter((prod)=>{return this.search.toLowerCase().split(' ').every(v => prod.nome.toLowerCase().includes(v))
      })
      }else{
        return this.details.produtos;
      }
    },
    nfDetails(){
        return  this.details.nf;
    }
  },
  methods: {
    nfChaveUrlCpf:  function () {
        this.chaveNF = this.details.nf.chave;
        this.urlNF = this.details.nf.url;  
        this.cpf = this.details.cpf;
    }
  }
}
</script>

<style scoped>
input:focus, button:focus {
  border: 1px solid var(--main-bg) !important;
  box-shadow: none !important;
}
.form-check-input:checked {
  background-color: var(--main-bg) !important;
  border-color: var(--main-bg) !important;
}
.card, .btn, input{
  border-radius:0 !important;
}
.search-dois i{
    left: 25px;
    height: 20px;
    line-height: 23px;
    width: 5px;
    position: absolute;
}
.search-dois input{
    height: 25px;
    outline: none;
    width: 100%;
    font-size: 17;
    border-radius: 5px;
    padding: 0 15px 0 43px;
    border:  1px solid #D8D8D8;
    
}
/*  Parte do accordion */
.accordion {
    max-width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: hidden;
    font-family: "Quicksand", sans-serif;
    background: #4E9BD8;
  }
  
  .accordion__label,
  .accordion__content {
    padding: 14px 20px;
  }
  
  .accordion__label {
    display: block;
    color: #ffffff;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    transition: background 0.1s;
  }
  
  .accordion__label:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  
  .accordion__label::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    width: 12px;
    height: 6px;
    background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:%23FFFFFF99;" /></svg>');
    background-size: contain;
    transition: transform 0.4s;
  }
  
  .accordion__content {
    background: #ffffff;
    line-height: 1.6;
    font-size: 0.85em;
    display: none;
  }
  
  .accordion__input {
    display: none;
  }
  
  .accordion__input:checked ~ .accordion__content {
    display: block;
}
  
  .accordion__input:checked ~ .accordion__label::after {
    transform: translateY(-50%) rotate(0.5turn);
}
  
/*  Parte da table */
  th {
    border: 5px solid transparent;
    background-clip: padding-box;
}
.invoives-table{
    overflow: scroll;
    padding-top: 5px;
    border-collapse:  collapse;
    width: 100%;
    background-color: #fff;
    border-color: #ffffff;
    border-radius: 5px;
    margin: 25px auto;
}
.invoives-table thead{
    border-bottom:  1px dashed grey;
}
.invoives-table tbody{
    border-bottom:  1px dashed grey;
}
.invoives-table tfoot{
    border-bottom:  1px dashed grey;
}
.invoives-table thead tr td{
    font-size: 12px;
    letter-spacing: 1px;
    color: #6699CC;
    padding: 8px;
}
.invoives-table tfoot tr td{
    font-size: 12px;
    letter-spacing: 1px;
    color: #6699CC;
    padding: 8px;
}
.invoives-table tbody tr td{
    padding: 8px;
}
.invoives-table tbody tr td:nth-child(4){
    text-align: center;
}
.invoives-table tfoot tr td:nth-child(2){
    text-align: right;
}
</style>