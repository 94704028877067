<template>
  <router-view />
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
:root{
  --main-bg:#D8D8D8;
}
.main-bg {
  background: var(--main-bg) !important;
}

</style>